<script>
  import "../app.css";

  import Header from "../components/Header.svelte";
</script>

<svelte:head>
  <title>Colorglyph</title>
</svelte:head>

<main>
  <Header />
  <div class="p-2">
    <slot />
  </div>
</main>